
import Vue from 'vue';
import CustomTableN from '@/core/components/shared/CustomTableN.vue';
import { Component } from 'vue-property-decorator';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import EntitySearch from '@/core/components/shared/EntitySearch.vue';
import TerritorySearch from '@/core/components/shared/TerritorySearch.vue';
import CommoditySearch from '@/core/components/shared/CommoditySearch.vue';
import { DeletePreparation } from '@/freight/application/Preparation/delete/DeletePreparation';
import { Preparation } from '@/freight/domain/Preparation/Preparation';
import { PreparationForList } from '@/freight/domain/Preparation/PreparationForList';
import { FindAllPreparations } from '@/freight/application/Preparation/search/FindAllPreparations';
import AddNewPreparationForm from './AddNewPreparationForm.vue';
@Component({
  components: {
    CustomTableN,
    EntitySearch,
    TerritorySearch,
    CommoditySearch,
    AddNewPreparationForm
  },
  name: 'PreparationCenter'
})
export default class PreparationCenter extends Vue {
  @Inject(TYPES.CARGO_DETAILS_PRESET_FIND_ALL)
  private readonly findAllCargoDetailsPreset!: FindAllPreparations;
  @Inject(TYPES.CARGO_DETAILS_PRESET_DELETE)
  private readonly deleteCargoDetailsPreset!: DeletePreparation;

  preparations: PreparationForList[] = [];
  isLoading = false;
  isEditing = false;

  form: Preparation = new Preparation();

  $refs!: {
    preparationForm: AddNewPreparationForm;
  };

  get columns() {
    return [
      {
        key: 'customer',
        label: this.$t('general.customer'),
        sortable: true,
        field: 'customer'
      },
      {
        key: 'shipper',
        label: this.$t('general.shipper'),
        sortable: true,
        field: 'shipper'
      },
      {
        key: 'consignee',
        label: this.$t('general.consignee'),
        sortable: true,
        field: 'consignee'
      },
      {
        key: 'address',
        label: this.$t('general.address'),
        sortable: true,
        field: 'addressLine'
      },
      {
        key: 'commodity',
        label: this.$t('general.commodity'),
        sortable: true,
        field: 'commodity'
      },
      {
        key: 'status',
        label: this.$t('general.status'),
        sortable: true,
        field: 'status',
        formatter: (value: string) => {
          return this.$t(`general.${value}`);
        }
      },
      {
        key: 'actions',
        field: 'actions',
        label: this.$t('general.actions')
      }
    ];
  }

  actions = {
    customActions: [
      {
        title: `${this.$t('general.edit')}`,
        icon: 'fa fa-pencil',
        variant: 'secondary',
        action: this.loadPreparation
      },
      {
        title: `${this.$t('general.delete')}`,
        icon: 'fa fa-trash',
        variant: 'danger',
        action: this.deletePreset
      }
    ]
  };

  mounted() {
    this.findPresets();
  }

  async findPresets() {
    try {
      this.isLoading = true;
      this.preparations = await this.findAllCargoDetailsPreset.execute();
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  async deletePreset(cargoPreset: PreparationForList) {
    try {
      this.isLoading = true;
      await this.deleteCargoDetailsPreset.internalExecute(cargoPreset.id);
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.findPresets();
    }
  }

  async loadPreparation(preparation: Preparation) {
    try {
      this.isLoading = true;
      await this.$refs.preparationForm.loadPresetToForm(preparation);
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }
}
