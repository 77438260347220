import { AddressLine } from '@/settings/domain/address/AddressLine';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import { CommodityType } from '../commodity_type/CommodityType';
import { PreparationBulk } from '@/freight/domain/preparationBulk/domain/PreparationBulk';

export class Preparation {
  id: string;
  customer: Entity | null;
  shipper: string;
  consignee: string;
  addressLine: AddressLine;
  commodity: CommodityType | null;
  bulks: PreparationBulk[] = [];
  dangerousCargo: boolean;
  status: 'NOT_USED' | 'USED';

  public constructor() {
    this.id = '';
    this.customer = null;
    this.shipper = '';
    this.consignee = '';
    this.addressLine = new AddressLine();
    this.commodity = null;
    this.dangerousCargo = false;
    this.status = 'NOT_USED';
  }
}
